<template lang="pug">
    .dashboard.fill-height
        DashboardNav
        component(v-if="activeDashboardComponent && activeDashboardComponent.name", :is="activeDashboardComponent", :key="page || 0")
</template>

<script>
import { get, call } from "vuex-pathify";
import DashboardNav from "@/components/CRM/Nav";
export default {
  name: "Dashboard",
  components: { DashboardNav },
  watch: {
    $route() {
      this.init();
    }
  },
  data() {
    return {
      page: null
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      activeDashboardComponent: "CrmNav/activeDashboardComponent"
    })
  },
  methods: {
    ...call({
      setActiveDashboardComponent: "CrmNav/setActiveDashboardComponent"
    }),
    init() {
      this.page = this.$route.params.page;
      this.setActiveDashboardComponent({ page: this.page });
    }
  }
};
</script>
