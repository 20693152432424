<template lang="pug">
    .dashboard-nav
        v-navigation-drawer(app permanent v-model="showNav", :mini-variant.sync="miniNav")
            template(template v-slot:prepend)
                .ma-3
                    v-btn(icon color="white" @click="miniNav = !miniNav").mx-auto.accent
                        v-icon {{miniNav ? "mdi-menu" : "mdi-close"}}
            .mt-12
                template(v-if="branding")
                  v-list-item(v-if="!miniNav", key="brand" link :to="home.link").brand.text-center.mb-6
                      v-img(:src="branding.logo" max-width="150", max-height="120", contain).mx-auto
                  
                  v-list-item(v-for="item in items", :key="item.text" link :to="item.link")
                      v-list-item-icon
                        v-icon().mr-2 {{item.icon}}
                      v-list-item-content
                        span  {{item.text}}
                template(v-else)
                  pre.debug Branding is uknown?


</template>

<script>
import { get, sync } from "vuex-pathify";
export default {
  name: "DashboardNav",
  data() {
    return {
      items: [
        {
          text: "Leads",
          icon: "mdi-account-plus",
          link: "/crm/Leads"
        },
        {
          text: "Insights",
          icon: "mdi-home",
          link: "/crm/Insights"
        },

        { text: "Profile", icon: "mdi-cog", link: "/crm/Profile" },
        { text: "Logout", icon: "mdi-logout", link: "/logout" }
      ]
    };
  },
  computed: {
    ...get({ branding: "User/agencyData@branding" }),
    ...sync({
      showNav: "CrmNav/showNav",
      miniNav: "CrmNav/miniNav"
    }),
    home() {
      return this.items.find(itm => itm.link === "/crm/Insights");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors-app.scss";
.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  ::v-deep
  .v-navigation-drawer__border {
  background-color: $accent;
  opacity: 0.3;
}
.v-list-item--active {
  &:before {
    color: white;
  }

  .v-list-item__content {
    font-weight: bold;
    color: $primary;
    z-index: 1;
  }

  .v-icon {
    color: $secondary;
  }
}
</style>
